import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8f04537e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _544757fc = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _0b2841e0 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "pages/Category" */))
const _48ef6e28 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _26e24f74 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _74dcbd02 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _15bc54f6 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _7855b4db = () => interopDefault(import('../pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _35347821 = () => interopDefault(import('../pages/CmsPreview.vue' /* webpackChunkName: "" */))
const _40a54604 = () => interopDefault(import('../pages/CookieDeclaration.vue' /* webpackChunkName: "pages/CookieDeclaration" */))
const _49f749ef = () => interopDefault(import('../pages/DesignSystem.vue' /* webpackChunkName: "" */))
const _10189cef = () => interopDefault(import('../pages/MyAccount.vue' /* webpackChunkName: "pages/MyAccount" */))
const _08b2ebf1 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _680ee06a = () => interopDefault(import('../pages/PayloadPage.vue' /* webpackChunkName: "pages/PayloadPage" */))
const _71483cc6 = () => interopDefault(import('../pages/Playground.vue' /* webpackChunkName: "" */))
const _4bba9d7d = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _693350d0 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _7d488a94 = () => interopDefault(import('../pages/Rest.vue' /* webpackChunkName: "" */))
const _34cba952 = () => interopDefault(import('../pages/Route.vue' /* webpackChunkName: "pages/Route" */))
const _6f8b500a = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "pages/Search" */))
const _1b5f242e = () => interopDefault(import('../pages/TranslatorTest.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _8f04537e,
    meta: {"hideNavigation":true},
    name: "home___en"
  }, {
    path: "/it",
    component: _8f04537e,
    meta: {"hideNavigation":true},
    name: "home___it"
  }, {
    path: "/en/Cart",
    component: _544757fc,
    name: "Cart___en"
  }, {
    path: "/en/Category",
    component: _0b2841e0,
    name: "Category___en"
  }, {
    path: "/en/checkout",
    component: _48ef6e28,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___en"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Checkout",
    component: _48ef6e28,
    name: "Checkout___en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___en"
    }]
  }, {
    path: "/en/cms-preview",
    component: _35347821,
    name: "cms-preview___en"
  }, {
    path: "/en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___en"
  }, {
    path: "/en/CookieDeclaration",
    component: _40a54604,
    name: "CookieDeclaration___en"
  }, {
    path: "/en/cookies-policy",
    component: _40a54604,
    name: "cookie-declaration___en"
  }, {
    path: "/en/design-system",
    component: _49f749ef,
    meta: {"hideNavigation":true},
    name: "Design System___en"
  }, {
    path: "/en/DesignSystem",
    component: _49f749ef,
    name: "DesignSystem___en"
  }, {
    path: "/en/Home",
    component: _8f04537e,
    name: "Home___en"
  }, {
    path: "/en/MyAccount",
    component: _10189cef,
    name: "MyAccount___en"
  }, {
    path: "/en/Page",
    component: _08b2ebf1,
    name: "Page___en"
  }, {
    path: "/en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___en"
  }, {
    path: "/en/playground",
    component: _71483cc6,
    name: "playground___en"
  }, {
    path: "/en/Playground",
    component: _71483cc6,
    name: "Playground___en"
  }, {
    path: "/en/Product",
    component: _4bba9d7d,
    name: "Product___en"
  }, {
    path: "/en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___en"
  }, {
    path: "/en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___en"
  }, {
    path: "/en/rest",
    component: _7d488a94,
    name: "rest___en"
  }, {
    path: "/en/Rest",
    component: _7d488a94,
    name: "Rest___en"
  }, {
    path: "/en/Route",
    component: _34cba952,
    name: "Route___en"
  }, {
    path: "/en/Search",
    component: _6f8b500a,
    name: "Search___en"
  }, {
    path: "/en/search-result",
    component: _6f8b500a,
    name: "search-result___en"
  }, {
    path: "/en/translator-test",
    component: _1b5f242e,
    name: "translator-test___en"
  }, {
    path: "/en/TranslatorTest",
    component: _1b5f242e,
    name: "TranslatorTest___en"
  }, {
    path: "/it/Cart",
    component: _544757fc,
    name: "Cart___it"
  }, {
    path: "/it/Category",
    component: _0b2841e0,
    name: "Category___it"
  }, {
    path: "/it/checkout",
    component: _48ef6e28,
    name: "checkout___it",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___it"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___it"
    }]
  }, {
    path: "/it/Checkout",
    component: _48ef6e28,
    name: "Checkout___it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___it"
    }]
  }, {
    path: "/it/cms-preview",
    component: _35347821,
    name: "cms-preview___it"
  }, {
    path: "/it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___it"
  }, {
    path: "/it/CookieDeclaration",
    component: _40a54604,
    name: "CookieDeclaration___it"
  }, {
    path: "/it/cookies-policy",
    component: _40a54604,
    name: "cookie-declaration___it"
  }, {
    path: "/it/design-system",
    component: _49f749ef,
    meta: {"hideNavigation":true},
    name: "Design System___it"
  }, {
    path: "/it/DesignSystem",
    component: _49f749ef,
    name: "DesignSystem___it"
  }, {
    path: "/it/Home",
    component: _8f04537e,
    name: "Home___it"
  }, {
    path: "/it/MyAccount",
    component: _10189cef,
    name: "MyAccount___it"
  }, {
    path: "/it/Page",
    component: _08b2ebf1,
    name: "Page___it"
  }, {
    path: "/it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___it"
  }, {
    path: "/it/playground",
    component: _71483cc6,
    name: "playground___it"
  }, {
    path: "/it/Playground",
    component: _71483cc6,
    name: "Playground___it"
  }, {
    path: "/it/Product",
    component: _4bba9d7d,
    name: "Product___it"
  }, {
    path: "/it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___it"
  }, {
    path: "/it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___it"
  }, {
    path: "/it/rest",
    component: _7d488a94,
    name: "rest___it"
  }, {
    path: "/it/Rest",
    component: _7d488a94,
    name: "Rest___it"
  }, {
    path: "/it/Route",
    component: _34cba952,
    name: "Route___it"
  }, {
    path: "/it/Search",
    component: _6f8b500a,
    name: "Search___it"
  }, {
    path: "/it/search-result",
    component: _6f8b500a,
    name: "search-result___it"
  }, {
    path: "/it/translator-test",
    component: _1b5f242e,
    name: "translator-test___it"
  }, {
    path: "/it/TranslatorTest",
    component: _1b5f242e,
    name: "TranslatorTest___it"
  }, {
    path: "/en/c/:categoryId",
    component: _0b2841e0,
    name: "category___en"
  }, {
    path: "/en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___en"
  }, {
    path: "/en/p/:productId",
    component: _4bba9d7d,
    name: "product___en"
  }, {
    path: "/it/c/:categoryId",
    component: _0b2841e0,
    name: "category___it"
  }, {
    path: "/it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___it"
  }, {
    path: "/it/p/:productId",
    component: _4bba9d7d,
    name: "product___it"
  }, {
    path: "/en/:path+",
    component: _34cba952,
    name: "route-resolver___en"
  }, {
    path: "/it/:path+",
    component: _34cba952,
    name: "route-resolver___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
